import React from 'react'
import "./All_staking.css"
export default function All_staking() {
  return (
    <div className='main_all_staking'>
      
<div className="container">
        <h1 className="all_staing_head">ONE-STOP DESTINATION FOR ALL YOUR STAKING REQUIREMENTS</h1>
        <p className="all_stk_para site_pop">$AVENT offers a comprehensive solution for staking and unstaking your tokens. Select your preferred function among the five staking plans available. Utilize your NFTs as boosters to enhance your APY, ensuring that only staked tokens earn rewards.</p>
      </div>
    </div>
  )
}
